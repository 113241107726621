
/*-------------------------------------------------*/
/*	Sub Navigation
/*-------------------------------------------------*/

.nav-sub {
	font-size: 1.25rem;
	.nav {
		overflow: hidden;
		float: right;
		li {
			float: left;
			a {
				text-transform: uppercase;
			}
		}
	}
}

.sub-menu {
	position: absolute;
    top: 96%;
    left: auto;
    z-index: 1000;
    display: none;
    float: left;
    height: 125px;
    min-width: 200px;
    padding: 0 !important;
    margin: 2px 0 0;
    list-style: none;
    font-size: 16px;
    text-align: left;
    background-color: #002e5e;
    border: 1px solid #fff;
    border-top: 4px solid #fff;
    border-radius: 0px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.sub-menu li {
	border-bottom: 1px solid #000;
	width: 100%;
	padding: 17px !important;
}

.sub-menu li a {
	border: none !important;
}

.sub-menu li:last-child {
	border-bottom: none;
}

.sub-menu li.menu-item a:hover {
    color: #fff !important;
}

i.fa.fa-arrow-down {
	font-size: 30px;
	position: relative;
	left: 50%;
	display: none;
	color: #fff;
}

.nav-primary .nav li.active.menu-news a {
	border-bottom: 1px solid #fff;
}

.nav-primary .nav li.active.menu-team a {
	border-bottom: 1px solid #fff;
}

.nav-primary .nav li.active.menu-our-firm a {
	border-bottom: 1px solid #fff;
}

.nav-primary .nav li.active.menu-investment-strategies a {
	border-bottom: 1px solid #fff;
}

.nav-primary .nav li.active.menu-portfolio a {
	border-bottom: none;
}


/*-------------------------------------------------*/
/*	Main Navigation
/*-------------------------------------------------*/

.nav-primary {
	font-size: 1.5rem;
	
	@media(max-width: $screen-sm-max) {
		display: none;
	}

	.nav {
		position: absolute;
		bottom: 0;
		right: 15px;
		/*overflow: hidden;*/
		li {
			float: left;
			a {
				text-transform: uppercase;
				font-weight: 300;
				&:hover {
					color: $blue;
				}
			}
			/*&.active {
				a {
					border-bottom: 1px solid #fff;
				}
			}*/
		}
	}
}

@media (max-width: 991px) {

	/* === main_menu === */

	#menu-footer-navigation.nav>li>a:hover {
		color: #fff !important;
	}
	#menu-footer-navigation.nav>li>a:focus {
		color: #fff !important;
	}

	/* === sub_menu === */

	.sub-menu {
		position: relative;
		min-width: 100%;
		text-align: center;
	}

	.sub-menu .menu-item a {
    	background-color: transparent !important;
    }

    .nav-footer .nav li {
    	font-size: 1.25rem !important;
    }

    .nav-footer .nav li a:focus {
    	color: #fff !important;
    }
}

/*-------------------------------------------------*/
/*	Mobile Navigation
/*-------------------------------------------------*/

.mobile-trigger {

	.btn-default {
		margin: 0;
		padding: 1rem 1.5rem;
		margin: 0;
	}

}
/*-------------------------------------------------*/
/*	Footer Navigation
/*-------------------------------------------------*/

.nav>li>a:focus, .nav>li>a:hover {
    text-decoration: none;
    background-color: #eee;
    color: #00325d!important;
}

.nav-footer {
	font-size: 1.25rem;
	text-align: center;
	.nav {
		overflow: hidden;
		margin: auto;
		display: inline-block;
		@media(max-width: $screen-sm-max) {
			width: 100%;
		}
		li {
			float: left;
			@media(max-width: $screen-sm-max) {
				float: none;
				width: 100%;
				text-align: center;
				margin-bottom: 2px;
			}
			a {
				text-transform: uppercase;
				color: #fff;
				&:hover, focus {
					color: #fff;
				}
				@media(max-width: $screen-sm-max) {
					background: rgba(255,255,255,0.1);
				}
			}
		}
	}
}

/*-------------------------------------------------*/
/*	Tab Navigation
/*-------------------------------------------------*/


.nav-tabs.nav-justified {
    margin: 4rem 0;
    >li {
    	>a {
    		padding: 2rem;
    		border-radius: 0;
    		border: none ;
    		border-bottom: 1px solid #ccc;
    		border-left: 1px solid #ccc;
    		border-top: 1px solid #ccc;
    		text-transform: uppercase;

    		@media (max-width: $screen-xs-max) {
    			border-right: 1px solid #ccc;
    		}
    	}
    	&:last-of-type {
    		a {
    			border-right: 1px solid #ccc;
    		}
    	}
    	&.active {
    		>a {
    			z-index: 99;
    			border-right: 1px solid #ccc;
    			box-shadow: 0 5px 10px rgba(0,0,0,0.25), 0 5px 0px $blue inset;
    			color: $blue ;
    		}
    	}
    }
}

.nav-tabs.nav-stacked {
    >li {
    	margin-top: 0;
    	>a {
    		padding: 2rem;
    		border-radius: 0;
    		border-right: 1px solid #ccc;
    		border-left: 1px solid #ccc;
    		border-top: 1px solid #ccc;
    		border-bottom: 1px solid transparent;
    		text-transform: uppercase;
    	}
    	&:last-of-type {
    		a {
    			border-bottom: 1px solid #ccc;
    		}
    	}
    	&.active {
    		>a {
    			z-index: 99;
    			box-shadow: 3px 1px 10px rgba(0,0,0,.25), 0 5px 0px $blue inset;
    			color: $blue ;
    		}
    	}
    }
}

/*-------------------------------------------------*/
/*	Filters Navigation
/*-------------------------------------------------*/

.filters.nav {
	clear:both;
	overflow: hidden;
	li {
		float: left;

		a {

			&.collapsed {
				background: #fff;
			}
		}
	}
}

#toggle-filters {
	.well {
		margin-bottom: 0 !important;
		border: none;
	}
}




