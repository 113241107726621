/*-------------------------------------------------*/
/*	Team Members
/*-------------------------------------------------*/


.post-type-archive-team-members {

	.filters-team-members {
		@include make-column(3,3,4,12);
		margin-bottom: 2rem;
	}

	.display-team-members {
		@include make-column(9,9,8,12);
		padding: 0;
		.team-member {
			width: 25%;
			float: left;
			text-align: center;
			position: relative;
			padding: 0 15px;
			margin-bottom: 3rem;
			@media (max-width: $screen-sm-max) {
				width: 50%;
			}
			@media (max-width: $screen-xs-max) {
				width: 100%;
			}

			a {
				display: block;

				&:hover {
					text-decoration: none;
				}
			}

			.img-responsive {
				width: 100%;
			}
			.name {
				font-size: 1.75rem;
				text-transform: uppercase;
			}
			p {
				font-size: 1.25rem;
				line-height: 1.5rem;
				margin-bottom: 0;
			}

			.fa-user {
			    font-size: 16rem;
			    display: block;
			    background-color: #f0f0f0;
			    color: #fff;
			    line-height: 235px;
			}

		}
		&.no-images {
			.team-member {
				img {
					display: none;
				}
			}
		}

		&.all-professionals {
			.team-member {
				width: 25%;
				float: left;
				text-align: center;
				position: relative;
				margin-bottom: 1rem;
				@media (max-width: $screen-sm-max) {
					width: 50%;
				}
				@media (max-width: $screen-xs-max) {
					width: 100%;
				}

				a {
					display: block;
					padding: 2rem;
					min-height: 11rem;
					border: 1px solid transparent;

					&:hover {
						text-decoration: none;
						border-color: $blue;
						background-color: rgba(0,0,0,0.05);
					}
				}
			}	
		}
	}
}

.single-team-members {
	.team-member-img {
		@include make-column(6,6,6,12);

		@media (max-width: $screen-xs-max) {
			margin-bottom: 2rem;
		    background-color: #f0f0f0;
		    padding: 2rem;
		}
		img {
			margin: auto;
		}
	}
	.team-members {
		@include make-column(6,6,6,12);
		header {
			h1 {
				margin: 1rem 0;
			}
			h2 {
				margin: 0 0 2rem 0;
				font-size: 2.5rem;
				font-weight: 600;
			}
			p {
				text-transform: uppercase;
				margin: 0;
			}
		}
	}
}


/*-------------------------------------------------*/
/*	Portolfio Items
/*-------------------------------------------------*/

.post-type-archive-portfolio {

	.main {
		padding-left: 15px;
		padding-right: 15px;
	}

	.nav-tabs.nav-justified {
	    margin: 4rem 0 2rem 0;
	}

	.well {
		overflow: hidden;

		@media(max-width: $screen-xs-max) {
			padding: 1rem;
		}

		.btn-group {
			display: block;
			clear: both;
			margin: 1rem 0;
			overflow: hidden;
			
			.btn {
				@media(max-width: $screen-xs-max) {
					margin: 4px 1px 0 0;
			    	padding: 1rem 1rem;
			   		border-radius: 0;
				}
			}
		}
	}

	.alert {
		p {
			margin: 0;
		}
	}
}

.portfolio-items {
	margin-top: 2rem;
	&.grid-view {
		.portfolio-item {
			width: 19%;
			float: left;
			padding: 2rem;
			border: 1px solid $blue;
			margin: 0 0.5% 1% 0.5%;
			position: relative;
			overflow: hidden;
			height: auto;
			@media(max-width: $screen-sm-max) {
				width: 32%;
			}
			@media(max-width: $screen-xs-max) {
				width: 49%;
			}

			img {
				margin: auto;
			}

			figcaption {
			    background-color: $blue;
			    color: #fff;
			    position: absolute;
			    left: 0;
			    right: 0;
			    bottom: 0;
			    padding: 0.5rem 1rem;
			    transition: transform 0.5s ease;
			    transform: translateY(100%);
				
				.industry {
					margin-bottom: 0;
				}
			    .details {
			    	display: none;
			    }
			}
			&:hover {
				figcaption {
					transform: translateY(0);
				}
			}

		}
	}
	&.list-view {
		.portfolio-item {
			overflow: hidden;
			margin-bottom: 2rem;
			position: relative;
			width: 100%;

			@media(max-width: $screen-sm-max) {
				border-bottom: 1px solid #ccc;
				margin-bottom: 0rem;
			}

			a {
				display: block;
				float: left;
				border-right: 1px solid #ccc;
				width: 30%;
				@media(max-width: $screen-sm-max) {
					width: 100%;
					border: none;
				}

				img {
					display: block;
					margin: auto;
				}
			}
			figcaption {
				position: absolute;
				left: 30%;
				top: 50%;
				transform: translateY(-50%);
				width: 70%;
				padding: 4rem;

				@media(max-width: $screen-sm-max) {
					position: static;
					width: 100%;
					padding-top: 0;
					padding-left: 3rem;
					float: left;
					transform: translateY(0);
					text-align: center;
				}

				.type {
					font-weight: 600;
					background-color: $blue;
					display: inline;
					color: #fff;
					padding: 0rem 1rem;
				}

				p {
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}
} 


.single-portfolio {
	.portfolio-img {
		@include make-column(6,6,6,12);
		img {
			margin: auto;
		}
	}
	.portfolio {
		@include make-column(6,6,6,12);
		header {
			h1 {
				margin: 1rem 0;
			}
			h2 {
				margin: 0 0 2rem 0;
				font-size: 2.5rem;
				font-weight: 600;
			}
			p {
				text-transform: uppercase;
				margin: 0;
			}
		}
		.property {
			margin-bottom: 0;

			span {
				font-weight: 600;
				color: $blue;
			}
		}

		.btn-default {
		    border-radius: 0;
		    padding: 1rem 4rem;
		    margin: 4rem 0;
		}
	}
}

/*-------------------------------------------------*/
/*	News - Blog
/*-------------------------------------------------*/

.blog {

	.content {
		padding: 0;
	}

	.breadcrumb {
	    margin-top: 4rem;
	    @media (max-width: $screen-xs-max) {
			display: none;
	    }
	}

	.post {
	    border-bottom: 2px solid $blue;
	    padding: 4rem 0;
	    &:first-of-type {
	    	border-top: 2px solid $blue;
	    }

	    .entry-title {

	    	@media (max-width: $screen-xs-max) {
				font-size: 8vw;
	    	}
	    	a {
	    		&:hover {
	    			text-decoration: none;
	    			color: lighten($blue, 10%);
	    		}
	    	}
	    }
	}

	.read-more {
		text-align: right;
		.btn-default {
		    border-radius: 0;
		    padding: 1rem 4rem;
		    @media (max-width: $screen-xs-max) {
				width: 100%;
		    }
		}
	}
	.blog-footer {
		text-align: center;
	}
}

.single {
	.breadcrumb {
	    margin-top: 4rem;
        @media (max-width: $screen-xs-max) {
    		display: none;
        }
	}
}



