
.sidebar {
	.widget {
		h3 {
			text-transform: uppercase;
			color: $blue;
			font-size: 2rem;
			border-bottom: 2px solid $blue;
			padding: 1rem 0;
		}
	}

	.recent-posts-2 {
		position: relative;
		ul {
			padding-left: 2rem; 
			li {
				margin-bottom: 2rem;
			}
		}
	}
	.archives-2 {
		position: relative;
		&:after {
			display: block;
			content: '\f0d7';
			font-family: 'FontAwesome';
			position: absolute;
			bottom: 0;
			right: 1.5rem;
			line-height: 5rem;
			font-size: 2rem;
		}
	}
}

#archives-dropdown-2 {
	appearance: none;
	width: 100%;
	padding: 1rem;
	border-radius: 0;
}