// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


.gform_fields {
    list-style-type: none;
    padding: 0;

    .gfield_label {
        display: none;
    }

    .gfield {
    	input,
    	textarea {
    		border-radius: 0;
    		padding: 1rem 2rem;
    		border: 1px solid #ccc;
    		width: 100%;
    		margin-bottom: 2rem;
    		-webkit-appearance: none;
    		&:focus {
    			outline: none;
    			border-color: lighten($blue, 20%);
    			color: lighten($blue, 20%);
    		}
    	}

    	.ginput_container_select {
		    margin-bottom: 2rem;
		    position: relative;
		    &:after {
		    	display: block;
		    	content: '\f0d7';
		    	font-family: 'FontAwesome';
		    	line-height: 48px;
		    	position: absolute;
		    	top: 0;
		    	right: 2rem;
		    }
			select {
			    appearance: none;
			    width: 100%;
			    border-radius: 0;
			    padding: 1rem 2rem;
			    &:focus {
			    	outline: none;
			    	border-color: lighten($blue, 20%);
			    	color: lighten($blue, 20%);
			    }
			}
		}
    }
}

.ginput_complex {
	label {
		display: none;
	}

	span {
		width: 49%;
		display: block;
		float: left;

		&:first-of-type {
			margin-right: 2%;
		}

		@media (max-width: $screen-xs-max) {
			width: 100%;
			margin: 0;
		}
	}
}

.gform_footer {
    text-align: right;
}

.gform_button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    padding: 1rem 5rem;
    transition: all 0.5s ease;

    &:hover {
    	background: $blue;
    	color: #fff;
    	border-color: transparent;
    }
}

.gform_confirmation_wrapper {
    background-color: #f0f0f0;
    padding: 4rem;
    font-size: 3rem;
    h1 {
    	font-size: 6rem;
    	margin-bottom: 0;
    }
}