.grav-form-names div {
	display: flex !important;
}

.page-header {
	h1 {
		font-size: 50px !important;
		font-weight: 300;
		color: $blue;
		text-align: center;
		@media (max-width: $screen-xs-max) {
			font-size: 7vw;
		}
	}
}

.intro-content {
	text-align: center;
}


.home {
	background: #002e5e;
	.main {
		margin-bottom: 0;
	}
	footer.content-info {
		margin-top: 0;
		position: static;
	}
}

.home-btn {
	background: transparent;
	border: 1px solid rgb(255, 255, 255);
	box-shadow: 0 3px 2px rgba(22,22,22,.21);
	font-size: 13px;
  	font-family: "Open Sans";
  	color: #fff;
  	font-weight: 400;
  	line-height: 1.2;
  	padding: 10px;
  	position: relative;
  	text-align: center;
  	text-shadow: 0 3px 2px rgba(22,22,22,.21);
  	text-transform: uppercase;
  	width: 150px;
  	height: 50px;
}

.home_bgs {
	min-height: 780px;
	background-size: cover !important;
	position: relative;
}

.home_headcap {
	font-weight: 200;
	font-family: 'Open Sans',Arial,sans-serif;
	position: absolute;
	text-align: center;
	left:50%;
	right: auto;
	top: 50%;
	bottom: auto;
	margin: 0;
	text-shadow: none;
	color: #fff;
	transform: translate(-50%,-50%);
	text-shadow: 0 3px 2px rgba(22,22,22,.21);
}

.home_headcap h2 {
	font-size: 2.6em !important;
}

.home_headcap span a:hover {
	text-decoration: none !important;
	cursor: pointer;
}

.home_headcap a {
	color: #fff !important;
}

.home_first {
	top: 42% !important;
}

.home_third {

	top: 50% !important;
}

@media screen and (max-width: 480px) {
	.home_third {
		bottom: -20% !important;
		top: none;
	}

	.home_first {
		top: 51% !important;
	}
}

.home_subcap {
	font-size: 25px;
	font-style: italic;
	font-family: 'Open Sans',Arial,sans-serif;
	font-weight: 300;
	line-height: 2em;
	max-width: 900px;
	text-shadow: 0 3px 2px rgba(22,22,22,.21);
}

@media screen and (max-width: 450px) {
	.home_subcap {
		font-size: 20px;
		width: 300px;
	}
}

/*-------------------------------------------------*/
/*	Our Firm
/*-------------------------------------------------*/

.our-firm {

//img {
//	width: 100%;
//	margin: 0 auto;
//}
	.main {
		padding-left: 15px;
		padding-right: 15px;
	}

	.tab-content {
		.tab-pane {
			overflow: hidden;
			ul {
				li {
					margin-bottom: 1rem;
				}
			}
		}
	}

	.icon-groups {
		overflow: hidden;
		margin: 2rem 0;

		figure {
			width: 20%;
			float: left;
			text-align: center;
			text-transform: uppercase;
			padding: 0 1rem;

			@media (max-width: $screen-xs-max) {
				width: 100%;
				margin-bottom: 2rem;

			}

			span {
				display: block;
				width: 100%;
				font-size: 8rem;
				margin: 2rem 0;
				color: $blue;
			}

			figcaption {
				font-size: 1.5rem;
				line-height: 2rem;
				font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
			}
		}
	}

	.no-h2 {
	margin-top: 1.5em
	}


}

/*-------------------------------------------------*/
/*	Investment Strategies
/*-------------------------------------------------*/

.investment-strategies {
	.tab-content {
		.tab-pane {
			overflow: hidden;
			clear: both;
			ul {
				li {
					margin-bottom: 1rem;
				}
			}
		}
	}
}

#operationally-focused {
	.btn-default {
		margin: 4rem 0 4rem 4rem;
		@media(max-width: $screen-xs-max) {
			margin-left: 0rem;
		}
	}
}


.strategy {
	.lists {
		@include make-column(9,9,9,12);
	}
	aside {
		@include make-column(3,3,3,12);
	}
	.list {
		@include make-column(5,5,5,12);
		/*&:nth-of-type(odd) {
			clear: both;
		}*/
		&:nth-of-type(even) {
			@include make-column-offset(1,1,1,0);
		}
		font-size: 1.5rem;

		h2 {
			color: $blue;
			text-transform: uppercase;
			font-size: 1.5rem;
			font-weight: 600;
			border-bottom: 2px solid;
			padding: 1rem 0;
		}
		ul {
			padding-left: 2rem;
		}
	}

	.icon-groups {
		overflow: hidden;

		figure {
			width: 100%;
			float: left;
			text-align: center;
			text-transform: uppercase;
			padding: 0 1rem;
			margin-bottom: 5rem;

			a {
				display: block;
				&:hover {
					text-decoration: none;
					color: lighten($blue, 50%);

					span {
						color: lighten($blue, 50%);
					}
				}
			}


			span {
				display: block;
				width: 100%;
				font-size: 8rem;
				margin-bottom: 1rem;
				color: $blue;
				transition: all 0.5s ease;
			}

			figcaption {
				font-size: 1.5rem;
				line-height: 2rem;
			}
		}
	}
}

/* ==== strategy ==== */

	.strategy .list {
		width: 33% !important;
		min-height: 250px;
	}

	.strategy .lists {
		width: 100% !important;
	}

	.strategy .list:nth-of-type(even) {
		margin-left: 0 !important;
	}

@media (max-width: 1200px) {
	.strategy .list:nth-of-type(odd) {
		clear: both;
	}

	.strategy .list {
		width: 50% !important;
	}
}

@media (max-width: 476px) {
	.strategy .list {
		width: 100% !important;
	}
}



/* ===== TEAM TAB ===== */

/* ==== responsive ==== */

@media (max-width: 991px) {
	.tm {
		text-align:center;
	}

	#team img.img-responsive {
		margin:0 auto;
	}

	div#team.tab-pane {
		margin: 0 auto;
	}
}

.tm {
	height: 416px;
	margin: 0 auto;
	text-align: center;
}

.tm img {
	margin: 0 auto;
}

h2.name {
	font-size: 1.75rem;
	text-transform: uppercase;
}

/*-------------------------------------------------*/
/*	Contact Us
/*-------------------------------------------------*/

.gform_validation_container {
	display:none;
}

.contact-us {
	.main {
		padding-left: 15px;
		padding-right: 15px;
	}

	.location {
		background-color: #f0f0f0;
		padding: 2rem;
		margin-bottom: 2rem;
		h1 {
			font-size: 2rem;
			margin: 0 0 1rem 0;
		}
		address {
			margin-bottom: 0;
		}

		a {
			&:hover {
				text-decoration: none;
				color: lighten($blue, 30%);
			}
		}
	}
}

#member-5 a{
	letter-spacing: -0.5px;
}

.team-disclaimer {
	display:block;
	padding: 0 15px;
}

.ep-disclaimer {
	display:block;
	padding: 0 15px;
}

.executive {
	margin-left: 0 !important;
}

.mobile {
	display: none;
}

.desktop {
    display: block;
}

.desktop a {
    display: block;
    margin: 0 !important;
    margin-left: 4rem !important;
}

.desktop .add-bm {
	margin-bottom: 4rem !important;
}

.desktop .add-tm {
	margin-top: 4rem !important;
}

@media (max-width: 991px){
	#operationally-focused .btn-default {
    	margin: 4rem 0 4rem 0rem;
	}
}

@media (max-width: 767px){
	#operationally-focused .btn-default {
    	margin: 2rem 0 2rem 0rem;
	}

	.executive {
		margin-bottom: 0 !important;
	}

	.mobile .btn-default {
		margin-bottom: 0 !important;
	}

	.desktop {
		display: none;
	}

	.mobile {
		display: inline;
	}
}

/*-------------------------------------------------*/
/*	Our-firm Draft
/*-------------------------------------------------*/


.page-id-4314 {

	.main {
		padding-left: 15px;
		padding-right: 15px;
	}

	.tab-content {
		.tab-pane {
			overflow: hidden;
			ul {
				li {
					margin-bottom: 1rem;
				}
			}
		}
	}

	.icon-groups {
		overflow: hidden;
		margin: 2rem 0;

		figure {
			width: 20%;
			float: left;
			text-align: center;
			text-transform: uppercase;
			padding: 0 1rem;

			@media (max-width: $screen-xs-max) {
				width: 100%;
				margin-bottom: 2rem;

			}

			span {
				display: block;
				width: 100%;
				font-size: 8rem;
				margin: 2rem 0;
				color: $blue;
			}

			figcaption {
				font-size: 1.5rem;
				line-height: 2rem;
				font-family: 'Open Sans',Helvetica,Arial,sans-serif !important;
			}
		}
	}
}
