// Grid system
.main {
	margin-bottom: 8rem;
	@include make-sm-column($main-sm-columns);
	padding: 0;
	.sidebar-primary & {
		@include make-sm-column($main-sm-columns - $sidebar-sm-columns);
	}
}
.sidebar {
	@include make-sm-column($sidebar-sm-columns);
}
