html {
    position: relative;
    min-height: 100%;
}

body {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	font-size: 16px;
	color: #555555;
	/*margin-bottom: 283px;*/
	padding-top: 104px;
	@media(max-width: $screen-md-max) {
		padding-top: 91px;
	}
	@media(max-width: $screen-sm-max) {
		margin-bottom: 0;
	}
	@media(max-width: $screen-xs-max) {
		padding-top: 20vw;
	}
}

a {
	color: $blue;
	transition: all 0.5s ease;
}

button {
	outline: none !important;
	&:focus {
		outline: none !important;
	}
}

h1 {
	color: $blue;
	font-weight: 300;
}

h2 {
	color: $blue;
	font-weight: 300;
}

.main {
	padding: 0;
}

.btn-default {
	border-radius: 0;
	// padding: 1rem 4rem;
	// margin: 4rem 0;
}



.breadcrumb {
	margin-top: 5rem;
	li+li:before {
		content: "\f105";
		padding: 0 5px;
		color: #ccc;
		font-family: FontAwesome;
	}
}

.pull-right {
	@media(max-width: $screen-xs-max) {
		float: none !important;
		width: 100%;
	}
}
