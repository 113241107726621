

.carousel {

	.item {
		min-height: 780px;
		background-size: cover !important;
		background-position: center top !important;

		@media(max-width: $screen-sm-max) {
			min-height: 550px;
		}

		@media(max-width: $screen-xs-max) {
			min-height: 400px;
		}

		a:hover {
			.carousel-caption {
				transform: scale(1.25) translate(-39.5%,-39.5%);
			}
		}

		.carousel-caption {
			left: 50%;
			right: auto;
			padding: 2rem 4rem;
			top: 50%;
			bottom: auto;
			margin: 0;
			text-shadow: none;
			color: #fff;
			transform: translate(-50%,-50%);
			opacity: 0;
			transition: all 0.5s ease;
			background-color: rgba(0,0,0,0.5);
			@media(max-width: $screen-xs-max) {
				width: 80%;
				padding: 2rem;
			}

			h1 {
				font-size: 3.75vw;
				margin: 0 0 0.5rem 0;
				transform: translateY(-15rem);
				transition: all 0.5s ease;
				@media(max-width: $screen-xs-max) {
					font-size: 3rem;
				}
				    
			}
			p {
				font-size: 1.25vw;
				transition: all 0.5s ease;
				transform: scale(0.1);
				margin-bottom: 0;
				
				@media(max-width: $screen-sm-max) {
					font-size: 2rem;
				}
				@media(max-width: $screen-xs-max) {
					font-size: 2rem;
				}
				    
			}
			a {

				&:hover,
				&:focus {
					text-decoration: none;
					color: $blue;
				}

				@media(max-width: $screen-xs-max) {
					width: 100%;
				}
			}
		}

		&.active {
			.carousel-caption {
				opacity: 1;
				h1 {
					transform: translateY(0);
				}

				p {
					transform: scale(1);
				}

				a {
					transform: translate(-50%,0);
				}
			}
		}
	}

	.carousel-control {
		background-image: none !important;
		@media(max-width: $screen-sm-max) {
			width: 10%;
		}
	}

	.carousel-indicators {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 15;
		width: 100%;
		margin: 0;
		padding-left: 0;
		list-style: none;
		right: 0;
		text-align: center;
		background-color: rgba(255,255,255,0.5);
		line-height: 3rem;

		@media(max-width: $screen-sm-max) {
			display: none;
		}

		li {
			border-color: $blue;

			&.active {
				background: $blue;
			}
		}
	}
}






