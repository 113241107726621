
footer.content-info {
	background: url(../images/footer-bg.jpg) center top no-repeat #002e5e;
	clear: both;
	margin-top: 1rem;
	background-size: contain;
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;

	@media(max-width: $screen-sm-max) { 
		position: static;
	}

	.brand {
		display: block;
		text-align: center;
		margin: 5rem 0;

		img {
			margin: auto;
		}
	}

	.footer-bottom {
		text-align: center;
		color: rgba(255,255,255,0.5);
		p {
			margin: 2rem 0 0.5rem 0;
		}
		a {
			color: rgba(255,255,255,0.5);
		}
	}
}
