@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?59opcd');
	src:url('../fonts/icomoon.eot?#iefix59opcd') format('embedded-opentype'),
		url('../fonts/icomoon.ttf?59opcd') format('truetype'),
		url('../fonts/icomoon.woff?59opcd') format('woff'),
		url('../fonts/icomoon.svg?59opcd#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-breifcase:before {
	content: "\e900";
}
.icon-cogs:before {
	content: "\e901";
}
.icon-swoop:before {
	content: "\e902";
}
.icon-building:before {
	content: "\e903";
}
.icon-handshake:before {
	content: "\e904";
}
.icon-invest:before {
	content: "\e905";
}
.icon-team:before {
	content: "\e906";
}
.icon-chart:before {
	content: "\e907";
}
