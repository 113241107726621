
header.banner {
	
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	-webkit-backface-visibility: hidden;

	.header-top {
		transition: all 0.5s ease;
		overflow: hidden;
		max-height: 50px;
		display: none;
		@media(max-width: $screen-sm-max) {
			max-height: 0;
		}
	}

	.header-main {
		background: #002e5e;
		color: #fff;
		padding: 2rem;

		.container {
			position: relative;
			padding: 0;
		}
		
		.brand {
		    display: block;
		    @include make-column(3,3,4,8);
		    img {
		    	max-width: 100%;
		    }
		}

		a {
			color: #fff;
		}
		
		.mobile-trigger {
			@include make-column(12,12,8,4);
			display: none;
			text-align: right;
			@media(max-width: $screen-sm-max) {
				display: block;
			}
			.btn {
				color: $blue;
				border-radius: 0;
				border: none;
			}
		}
		#collapse-mobile {
			@include make-column(12,12,12,12);
			text-align: center;
			.nav-mobile {
				margin: 2rem 0 0 0;
				li {
					margin-bottom: 2px;
					a {
						background-color: rgba(255,255,255,0.1);
					}
				}
			}
		}
	}
}